import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

// Setup : HTTP Request
import AuthApiProvider from "@/services/providers/AuthApiProvider";
const auth_api_provider = new AuthApiProvider();

// Import : Modules
import user from "./modules/user";
import product from "./modules/product";
import activity from "./modules/activity";

// Setup : VuexPersistence
const vuexLocal = new VuexPersistence({
  key: "nichi_storage",
  storage: window.localStorage,
  reducer: (state) => ({ access_token: state.access_token }),
});

export default new Vuex.Store({
  state: {
    access_token: null,
    is_loading: false,
    is_open_error_dialog: false,
    error_msg: null,
  },
  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.access_token = payload;
    },
    CLEAR_ACCESS_TOKEN(state) {
      state.access_token = null;
    },
    SET_LOADING(state) {
      state.is_loading = true;
    },
    SET_LOADED(state) {
      state.is_loading = false;
    },
    DISPLAY_ERROR_DIALOG(state, payload) {
      state.is_open_error_dialog = true;
      state.error_msg = payload;
    },
    HIDE_ERROR_DIALOG(state) {
      state.is_open_error_dialog = false;
    },
  },
  actions: {
    async requestCreateAccessToken({ commit }, params) {
      commit("SET_LOADING");
      try {
        const response = await auth_api_provider.requestCreateAccessToken(
          params
        );
        commit("SET_LOADED");
        if (response) {
          commit("SET_ACCESS_TOKEN", response.accessToken);
          return true;
        } else {
          commit("SHOW_USER_PROFILE_FORM");
          return false;
        }
      } catch (error) {
        commit("DISPLAY_ERROR_DIALOG", error);
      }
      commit("SET_LOADED");
    },
  },
  modules: { product, user, activity },
  plugins: [vuexLocal.plugin],
});
